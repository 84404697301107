/* eslint-disable no-useless-escape */
import { ROUTES } from 'static/menusConstants'
import moment from 'moment'

import { isLocalStorage } from 'utils'
import { getAvgReturAllnCall, getIndustriesAllCall } from 'api/calls'

const getLiquidity = val =>
  val === 'Low' ? 'Limited' : val === 'Moderate' ? 'Limited' : val === 'Very High' ? 'Liquid' : 'None'

const getRiskRangeShort = risk => (risk <= 25 ? 'Low' : risk <= 65 ? 'Mod' : risk <= 85 ? 'High' : 'V.High')

const getRiskRange = risk => (risk <= 25 ? 'Low' : risk <= 65 ? 'Moderate' : risk <= 85 ? 'High' : 'Very High')

const getRoundVal = (val, roundTo = 1) =>
  +val > 0 ? `+${val?.toFixed(roundTo) || 0}` : `${val?.toFixed(roundTo) || 0}`

export const moveItemsToFront = (platforms, slugsToMove) => {
  // Ensure vinovest and masterworks are always first
  const prioritizedSlugs = [
    'vinovest',
    'masterworks',
    ...slugsToMove.filter(slug => slug !== 'vinovest' && slug !== 'masterworks')
  ]

  // Filter out the items to move to the front in the order of prioritizedSlugs
  const itemsToMove = prioritizedSlugs.map(slug => platforms.find(item => item.slug === slug)).filter(Boolean)

  // Filter out the rest of the items
  const remainingItems = platforms.filter(item => !prioritizedSlugs.includes(item.slug))

  // Combine the arrays, moving itemsToMove to the front
  return [...itemsToMove, ...remainingItems]
}

const getLearnType = recordType => {
  let url = ''

  switch (recordType) {
    case 'VIDEO_REVIEW':
      url = `${ROUTES.learnReviewVideo}`
      break

    case 'REVIEW':
    case 'PLATFORM-REVIEW':
      url = `${ROUTES.learnReview}`
      break

    case 'COLLECTION':
      url = `${ROUTES.collections}`
      break

    case 'ARTICLE':
      url = `${ROUTES.learnArticle}`
      break

    case 'ARTICLE_QUESTIONS_ANSWERS':
      url = `${ROUTES.learnArticleQA}`
      break

    default:
      url = `/${ROUTES.learnArticle}`
  }

  return url
}

const getTimeAgoValue = (date, withAgo = false) => {
  if (!date) return ''

  const closeDate = new Date(date)
  const nowDate = new Date()
  const difference = nowDate.getTime() - closeDate.getTime()

  const hoursAgoNumber = (difference / (1000 * 3600)).toFixed()
  const daysAgoNumber = (difference / (1000 * 3600 * 24)).toFixed()

  if (+hoursAgoNumber === 0 && +daysAgoNumber === 0) return 'Recently'
  // If less than 1 day return hours amount
  if (+daysAgoNumber < 1)
    return +hoursAgoNumber === 1
      ? `${hoursAgoNumber} hour${withAgo ? ' ago' : ''}`
      : `${hoursAgoNumber} hours${withAgo ? ' ago' : ''}`
  // If days more than 1 return days amount
  if (+daysAgoNumber !== 0)
    return +daysAgoNumber === 1
      ? `${daysAgoNumber} day${withAgo ? ' ago' : ''}`
      : `${daysAgoNumber} days${withAgo ? ' ago' : ''}`

  return ''
}

const getDateFromTimestamp = timestamp => {
  const date = new Date(timestamp)

  const day = String(date?.getDate() || 0)
  const month = String((date?.getMonth() || 0) + 1 || 0)
  const year = String(date?.getFullYear() || 0)

  let hours = date?.getHours()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = String(hours % 12 === 0 ? 12 : hours % 12)
  const minutes = `${String(date?.getMinutes() || 0).length === 1 ? '0' : ''}${date?.getMinutes() || 0}`

  return `${month}/${day}/${year} at ${hours}:${minutes}${ampm}`
}

const getTimeDifferenceInMinutes = timestamp => {
  const nowDate = new Date()
  const date = new Date(timestamp)

  const dif = nowDate - date
  const inDays = Math.round(dif / 1000 / 60)

  return inDays
}

const getCorrectSlug = slug => {
  let slugLocal = ''

  switch (slug) {
    case 'stock-trading':
      slugLocal = 'spx'
      break

    case 'crypto':
      slugLocal = 'bitcoin'
      break

    case 'collectibles':
      slugLocal = 'contemporary'
      break

    default:
      slugLocal = slug
  }

  return slugLocal
}

const isBlackListSlugs = slug => ['high-yield-saving', 'lending', 'startups', 'nft'].includes(slug)

const getRandomData = (data, count) =>
  new Array(count).fill(0).map(() => {
    const investment = data[Math.floor(Math.random() * data.length)]
    const { logo } = investment
    return logo
  })

const getDateData7D = values => {
  if (!values?.length) return values

  // Get most recent date
  const date = moment(values[values.length - 1].date, 'YYYY-MM-DD HH:mm:ss').subtract(7, 'd')

  const filteredValues = values.filter(({ date: datetime }) => moment(datetime, 'YYYY-MM-DD HH:mm:ss').toDate() >= date)

  return filteredValues
}

const findWebpart = (webpart, typewebpart) => webpart.find(({ type }) => type === typewebpart)

const validateEmail = email =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

const getObjective = value => {
  switch (value) {
    case '1':
      return 'Passive Income'

    case '2':
      return 'Long Term Growth'

    case '3':
      return 'Balanced Investing'

    default:
      return value
  }
}

const getTimeFrame = (timeframe, limits) => {
  if (limits?.length > 1) return true
  const limit = limits[0]

  switch (limit) {
    case '12':
      return timeframe < 12

    case '60':
      return timeframe < 60

    case '72':
      return timeframe >= 60

    default:
      return true
  }
}

const getAccredited = value => {
  if (value[0] === '1') return ['Accredited', 'All']
  return ['All']
}

const getfilterQuizResults = (investments, cashValue, riskValue, timeframeValue, accreditedValue) => {
  // cashValue convert numbers to names
  const cashValueParsed = cashValue?.map(item => getObjective(item))
  // accreditedValue numbers to names
  const accreditedValueParsed = getAccredited(accreditedValue) // accreditedValue?.map(item => convertAccredited(item))

  const filterredList = investments
    // CASH PAYMENTS - filter cashValue; objectives: string[]
    .filter(({ objectives }) => objectives?.find(element => cashValueParsed?.includes(element)))
    // RISK - filter riskValue; newRisks: string[]
    .filter(({ newRisks }) => newRisks?.find(element => riskValue?.includes(element)))
    // TIMEFRAME - timeframeNumber; timeframeValue: number
    .filter(({ timeframeNumber }) => getTimeFrame(timeframeNumber, timeframeValue))
    // ACCREDITED - accreditedValue; investors: string
    .filter(({ investors }) => accreditedValueParsed?.includes(investors))

  return filterredList
}

const getfilterQuiz = (investments, riskValue, cashValue, timeframeValue, accreditedValue) => {
  let filterredList = investments

  // RISK
  filterredList = riskValue?.length
    ? riskValue.reduce((acc, value) => [...acc, ...filterredList.filter(({ newRisks }) => newRisks[0] === value)], [])
    : filterredList

  // CASH PAYMENTS
  filterredList = cashValue?.length
    ? cashValue.reduce((acc, value) => {
        switch (value) {
          case '1':
            return [...acc, ...filterredList.filter(({ objectives }) => objectives.includes('Passive Income'))]

          case '2':
            return [...acc, ...filterredList.filter(({ objectives }) => objectives.includes('Long Term Growth'))]

          case '3':
            return [...acc, ...filterredList.filter(({ objectives }) => objectives.includes('Balanced Investing'))]

          default:
            return acc
        }
      }, [])
    : filterredList

  // TIMEFRAME
  filterredList = timeframeValue?.length
    ? timeframeValue.reduce(
        (acc, value) => [...acc, ...filterredList.filter(({ timeframeNumber }) => timeframeNumber <= Number(value))],
        []
      )
    : filterredList

  // ACCREDITED
  if (!accreditedValue.includes('1')) filterredList = filterredList.filter(({ investors }) => investors === 'All')

  // Remove dublicates from array with objects
  filterredList = filterredList.reduce((acc, item) => {
    const isItemInArray = acc.find(({ id }) => id === item.id)
    return !isItemInArray ? acc.concat([item]) : acc
  }, [])

  return filterredList
}

const getTradingQuizStatus = () => isLocalStorage() && JSON.parse(localStorage.getItem('tradingQuiz'))
const setTradingQuizStatus = data => isLocalStorage() && localStorage.setItem('tradingQuiz', JSON.stringify(data))

const getUserEmailStatus = () => isLocalStorage() && JSON.parse(localStorage.getItem('userEmail'))
const setUserEmailStatus = data => isLocalStorage() && localStorage.setItem('userEmail', JSON.stringify(data))

const getGraphData = (positive = true) => {
  const graphData = Array.from(Array(20).keys()).map(key => ({
    x: key,
    y: Math.floor(Math.random() * 9)
  }))
  if (positive) {
    // positive
    graphData[graphData.length - 1].y = 10
    graphData[graphData.length - 2].y = 8
  } else {
    // negative
    graphData[graphData.length - 1].y = 2
    graphData[graphData.length - 2].y = 4
  }

  return graphData
}

const toMoneyFormat = (value, roundTo) => {
  // Protection from unquality backend responses
  if (typeof value !== 'number') {
    return String()
  }

  if (value === 0 || typeof value !== 'number') {
    return String(0)
  }

  const roundedValue = value.toFixed(Number.isInteger(roundTo) ? roundTo : 2)

  // Return 0.00000000
  if (Number(roundedValue) === 0) {
    return value.toFixed(8)
  }

  // Split decimal and integer
  const [beforeDot, aftetDot] = roundedValue.split('.')
  // Split only integet with ','
  return `${beforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${aftetDot ? `.${aftetDot}` : ''}`
}

const toPosOrNeg = (value, roundTo) =>
  value < 0 ? `${toMoneyFormat(value, roundTo)}` : `+${toMoneyFormat(value, roundTo)}`

const sortByPropDesc = prop => (a, b) => a[prop] > b[prop] ? -1 : b[prop] > a[prop] ? 1 : 0

const getMatchMeData = async (matches, investments, industriesAll, assetPagesSlugs) => {
  /* Prepare Platforms */

  // get platfroms from investments by matches ids
  const investmentsIds = matches?.map(investment => investment.id) || []
  const investmentsNew = investments.filter(({ id }) => !!investmentsIds.find(i => i === id))

  // get assets data slugs
  const assetsDataSlugs = investmentsNew.map(({ industries }) => industries?.[0]?.dataSlug)
  // get assets data slugs unique
  const assetsDataSlugsUnique = Array.from(new Set(assetsDataSlugs))
  // get assets slugs
  const assetsSlugs = investmentsNew.map(({ industries }) => industries?.[0]?.slug)
  // get assets slugs unique
  const assetsSlugsUnique = Array.from(new Set(assetsSlugs))
  // get average returns
  const avgReturnResponse = await getAvgReturAllnCall(assetsDataSlugsUnique)
  // combine average with empty slugs
  const avgResult = avgReturnResponse?.map(({ success, result: avg }, index) => ({
    slug: assetsSlugsUnique[index],
    average: success
      ? avg
      : assetsSlugsUnique[index] === 'startups'
      ? 1000
      : assetsSlugsUnique[index] === 'high-yield-saving'
      ? 10
      : 'Varied'
  }))

  // get assets industries
  let industriesResult = []

  // get cached version if exist
  if (industriesAll?.length) {
    industriesResult = assetsSlugsUnique?.map(slug => ({
      slug,
      assetThumbnail: industriesAll.find(({ slug: industrySlug }) => slug === industrySlug)?.thumbnail,
      assetName: industriesAll.find(({ slug: industrySlug }) => slug === industrySlug)?.name
    }))
  } else {
    // if no cached version get from endpoint
    const { success: industriesAllSuccess, result: industriesAllResult } = await getIndustriesAllCall()

    industriesResult = industriesAllSuccess
      ? assetsSlugsUnique?.map(slug => ({
          slug,
          assetThumbnail: industriesAllResult.find(({ slug: industrySlug }) => slug === industrySlug)?.thumbnail,
          assetName: industriesAllResult.find(({ slug: industrySlug }) => slug === industrySlug)?.name
        }))
      : []
  }

  // add average, asset thumbnail to investments
  const platforms = investmentsNew?.map(inv => {
    const averageValue = avgResult.find(({ slug }) => slug === inv?.industries?.[0]?.slug)?.average
    return {
      ...inv,
      averageValue,
      assetThumbnail: industriesResult.find(({ slug }) => slug === inv?.industries?.[0]?.slug)?.assetThumbnail,
      assetName: industriesResult.find(({ slug }) => slug === inv?.industries?.[0]?.slug)?.assetName,
      assetIndustry: inv?.industries?.[0]?.name,
      assetSlug: inv?.industries?.[0]?.slug,
      graph: getGraphData(averageValue === 'Varied' || averageValue > 0)
    }
  })

  /* Prepare Assets */

  // sort by average
  const invSorted = platforms?.sort(sortByPropDesc('averageValue'))
  // get filtered by unique average
  const averUnique = invSorted?.reduce((ac, cur) => {
    const accum = ac
    const ai = cur?.assetIndustry
    const isExist = accum?.find(({ assetIndustry }) => ai === assetIndustry)
    if (!isExist) accum.push(cur)
    return accum
  }, [])
  // get assets
  const assets = averUnique
    ?.map(({ averageValue, assetThumbnail, assetName, assetSlug, graph }) => ({
      averageValue,
      assetThumbnail,
      assetName,
      assetSlug,
      graph,
      isExist: !!assetPagesSlugs.find(slug => slug === assetSlug)
    }))
    ?.slice(0, 5)

  /* Prepare Reads */

  // get 3 slug names
  const readSlugs = averUnique?.map(({ assetName }) => assetName)?.slice(0, 3)

  return {
    platforms,
    assets,
    readSlugs
  }
}

export {
  getLiquidity,
  getRiskRange,
  getRoundVal,
  getRiskRangeShort,
  getLearnType,
  getTimeAgoValue,
  getDateFromTimestamp,
  getTimeDifferenceInMinutes,
  getCorrectSlug,
  isBlackListSlugs,
  getRandomData,
  getDateData7D,
  findWebpart,
  validateEmail,
  getfilterQuiz,
  getTradingQuizStatus,
  setTradingQuizStatus,
  getUserEmailStatus,
  setUserEmailStatus,
  getGraphData,
  toMoneyFormat,
  toPosOrNeg,
  getfilterQuizResults,
  getMatchMeData
}
